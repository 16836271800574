.w-100 {
    width: 100% !important;
}

.signatureCanvas {
    width: 100%;
    height: 100%;
}

.MuiInputBase-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.85) !important;
}

@page {
    size: 21cm 29.7cm;
    margin: 10mm 15mm 10mm 15mm;
    /* change the margins as you want them to be. */
}

@media print {
    body {
        width: 21cm;
        height: 29.7cm;
        margin: 10mm 15mm 10mm 15mm;
        /* change the margins as you want them to be. */
    }
}

.mainRoot {
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.blockRoot {
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    position: relative;
}

.blockRoot::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 10;
}